import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "50",
  viewBox: "0 0 50 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { id: "shield crossed out" }, [
      _createElementVNode("path", {
        id: "Vector",
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M47.5 5.52718L44.4728 2.5L2.5 44.4728L5.52718 47.5L47.5 5.52718ZM24.5301 2.94238L35.5475 6.18239L6.72834 35.0016C6.07017 33.3963 5.71501 31.6523 5.71501 29.8522V8.47549L24.5301 2.94238ZM24.5301 47.4377L15.2559 42.9743L43.3451 14.885V29.8522C43.3451 35.001 40.4249 39.7144 35.814 42.007L24.5301 47.4377Z",
        fill: "#212529"
      })
    ], -1)
  ])))
}
export default { render: render }